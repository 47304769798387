/* eslint-disable no-unused-vars */
import { all, takeEvery, select, call } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
// import moment from 'moment'
import _ from 'lodash'
import { rsFirebase, fireBase, fireStore } from '../../firebase'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    deleteDocument: firebase.deleteDocument,
    deleteCollection: firebase.deleteCollection,
  },
}

export function* SEND_MESSAGE({ payload }) {
  const now = fireBase.firestore.Timestamp.now()
  const messageId = `${now.seconds}-${now.nanoseconds}`

  const data = {
    id: messageId,
    roomId: payload.roomId,
    scheduleId: payload.schedule.id,
    message: payload.message,
    user: payload.user,
    deleted: false,
    highlight: false,
    createdTimestamp: now,
    createdAt: now.seconds,
    order: `${now.seconds}${now.nanoseconds}`,
  }
  const documentRef = `chat/${data.roomId}/messages/${messageId}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* TOGGLE_HIGHLIGHT({ payload }) {
  const highlighted = !payload.highlight
  const data = {
    highlight: highlighted,
  }
  const documentRef = `chat/${data.roomId}/messages/${payload.id}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* GET_MESSAGES({ payload }) {
  const { roomId, schedule, user, startDocId } = payload
  const isPublicChat = schedule.isPublicChat || false

  if (!roomId || schedule.chatEnabled === false) {
    return false
  }

  const collectionPath = `chat/${roomId}/messages`
  // const collectionRef = fireStore.collection(collectionPath)
  let collectionRef = null
  if (startDocId) {
    // console.log(startDocId)
    collectionRef = fireStore
      .collection(collectionPath)
      .orderBy('order', 'desc')
      .startAfter(startDocId)
    // .limit(20)
  } else {
    collectionRef = fireStore.collection(collectionPath).orderBy('order', 'desc')
    // .limit(20)
  }
  // if (!isPublicChat) {
  //   collectionRef = fireStore.collection(collectionPath).where('user.id', '==', user.id)
  // } else {
  //   collectionRef = fireStore.collection(collectionPath)
  // }
  const recentMessages = yield select(state => state.chat.messages)
  console.log('recent message', recentMessages)

  const successActionCreator = data => {
    // console.log(data.docs)
    let messageList = []
    // data.docChanges().forEach(change => {
    //   console.log(change)
    // })
    data.docs.forEach(message => {
      if (isPublicChat) {
        messageList.push(message.data())
      } else if (
        message.data().user.id === user.id ||
        message.data().user.role === 'admin' ||
        user.role === 'admin'
      ) {
        messageList.push(message.data())
      }
    })

    messageList = _.sortBy(messageList, 'order', 'asc')

    return {
      type: actions.SET_STATE,
      payload: { messages: messageList, finished: false },
    }
  }

  yield call(rsFirebase.firestore.syncCollection, collectionRef, {
    successActionCreator,
  })
}

export function* DELETE_MESSAGE({ payload }) {
  const documentRef = `chat/${payload.roomId}/messages/${payload.id}/`
  yield call(rsFirebase.firestore.deleteDocument, documentRef)
}
export function* TRUNCATE_ALL({ payload }) {
  const documentRef = `chat/${payload.roomId}`
  console.log('truncate chat', documentRef)

  // const result = yield call(rsFirebase.firestore.deleteDocument, documentRef)
  const { authProvider: authProviderName } = yield select(state => state.settings)

  const collectionPath = `chat/${payload.id}/messages`

  yield call(mapAuthProviders[authProviderName].deleteCollection, collectionPath, 200)

  yield call(mapAuthProviders[authProviderName].deleteDocument, 'chat', payload.id)
  // console.log(result)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHAT_SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.TOGGLE_HIGHLIGHT, TOGGLE_HIGHLIGHT),
    takeEvery(actions.CHAT_GET_MESSAGES, GET_MESSAGES),
    takeEvery(actions.CHAT_DELETE_MESSAGE, DELETE_MESSAGE),
    takeEvery(actions.CHAT_TRUNCATE_ALL, TRUNCATE_ALL),
  ])
}
